/*eslint-disable */
var $ = window.jQuery = require('jquery')
export default {
  name: 'conference-room-in-user-left',
  components: {},
  props: [],
  data () {
    return {
      hasGetFromRoute:''
    }
  },
  computed: {

  },
  mounted () {
    this.hasGetFromRoute = this.$route.hash
  },
  methods: {

  },
  beforeCreate: function() {
  },
  beforeRouteLeave: function(to, from, next) {
    if(to.name != 'videoChatOneToOnePeer'){
    }
    next();
  },
}


